<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titulo }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Razão Social -->

          <validation-provider
            #default="validationContext"
            name="Nome da Permissão "
            rules="required|alpha"
          >
            <b-form-group
              label="Nome do Permissão (subject)"
              label-for="nome-perfil"
            >
              <b-form-input
                id="nome-permissao"
                v-model="itemData.nome"
                name="nome_permissao"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe o nome da permissao"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Empresa rules="required" -->

          <b-form-checkbox
            v-model="itemData.abilities"
            value="Listar"
            class="custom-control-primary "
          >
            Listar
          </b-form-checkbox>
          <b-form-checkbox
            v-model="itemData.abilities"
            value="Atualizar"
            class="custom-control-secondary mt-1"
          >
            Atualizar
          </b-form-checkbox>
          <b-form-checkbox
            v-model="itemData.abilities"
            value="Salvar"
            class="custom-control-success mt-1"
            checked="checked"
          >
            Salvar
          </b-form-checkbox>

          <b-form-checkbox
            v-model="itemData.abilities"
            value="Excluir"
            class="custom-control-danger mt-1"
          >
            Excluir
          </b-form-checkbox>
          <b-form-checkbox
            v-model="itemData.abilities"
            value="Menu"
            class="custom-control-info mt-1"
          >
            Menu
          </b-form-checkbox>
          <b-form-checkbox
            v-model="itemData.abilities"
            value="Outra"
            class="custom-control-warning my-1"
          >
            Outra
          </b-form-checkbox>

          <validation-provider
            v-if="isOutra"
            #default="validationContext"
            name="Outra Habilidade"
            rules="required"
          >
            <b-form-group
              label="Outra Habilidade"
              label-for="Outra-Habilidade"
            >
              <b-form-input
                id="outra"
                v-model="itemData.outra"
                name="outra"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe o nome da Outra Habilidade"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BSidebar,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BFormCheckbox,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
    },

    listaUsuarios: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      nome: '',
      abilities: ['Salvar', 'Atualizar', 'Listar', 'Excluir', 'Menu'],
      outra: null,

    }

    // Use toast
    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-permissoes/store', itemData.value)
        .then(respo => {
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Permissão adicionada com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            const listaErros = error.response.data.errors
            for (const field in listaErros) {
              if (listaErros.hasOwnProperty(field)) {
                const errorMessages = listaErros[field]
                errorMessages.forEach(errorMessage => {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: errorMessage,
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  })
                })
              }
            }
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  computed: {
    isOutra() {
      return this.itemData.abilities.includes('Outra')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
