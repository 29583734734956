import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,

  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },

  },
  actions: {

    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/permissions', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`permissions/${item.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    store(ctx, itemData) {
      const permissoesLista = itemData.abilities.map(item => {
        if (item === 'Outra') {
          return null
        }

        return `${item.toLowerCase()} ${itemData.nome.toLowerCase()}`
      })
        .filter(item => item !== null)

      if (itemData.outra) {
        permissoesLista.push(`${itemData.outra.toLowerCase()} ${itemData.nome.toLowerCase()}`)
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/permissions', { names: permissoesLista })
          .then(response => resolve((response)))
          .catch(error => reject(error))
      })
    },

    deleteItem(ctx, { nome }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/permissions/${nome}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
