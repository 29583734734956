<template>
  <div>
    <form-create
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        :lista-usuarios="listaUsuarios"
        titulo="Nova Permissão"
        @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">
        Lista de Permissões
      </h5>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
              />
              <b-button
                  variant="success"
                  @click="isAddNewSidebarActive = true"
              >
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refItemListTable"
          class="position-relative"
          :items="fetchDados"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          :busy="isLoad"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"/>
            <strong> Carregando...</strong>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalItens"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <modalConfirmacao
          id="modal-confirmacao"
          titulo="Excluir Item"
          :item-id="dadosItem.uuid"
          :item-nome="dadosItem.nome"
          @itemConfirmado="excluirItem"
          @click="modalExcluir(data.item)"
      />
    </b-card>
    <div/>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'

import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import FormCreate from './FormCreate.vue'
import permissoesStoreModule from '../permissoesStoreModule'
import store from '@/store'
import useItemList from './useItemList'
import vSelect from 'vue-select'

export default {
  components: {
    FormCreate,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },

  setup() {
    const PERMISSOES_APP_STORE_MODULE_NAME = 'app-permissoes'

    // Register module
    if (!store.hasModule(PERMISSOES_APP_STORE_MODULE_NAME)) store.registerModule(PERMISSOES_APP_STORE_MODULE_NAME, permissoesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PERMISSOES_APP_STORE_MODULE_NAME)) store.unregisterModule(PERMISSOES_APP_STORE_MODULE_NAME)
    })

    const isAddNewSidebarActive = ref(false)
    const listaUsuarios = ref([])

    const {
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refItemListTable,
      refetchData,
      deleteItem,
    } = useItemList()

    return {
      isAddNewSidebarActive,
      listaUsuarios,
      deleteItem,
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refItemListTable,
      refetchData,

    }
  },
  data() {
    return {
      dadosItem: {
        uuid: '',
        nome: '',
      },

    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-permissoes'].load
    },
  },

  methods: {

    modalExcluir(item) {
      this.dadosItem.nome = item.name
      this.dadosItem.uuid = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')

    },

    excluirItem() {
      this.deleteItem(this.dadosItem)
    },
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
